<template>
  <layout name="OrganizLayout">
    <div class="Home">
      <!-- HERO -->
      <page-header/>
      <section>
        <div class="uk-container uk-container-small uk-margin-large-bottom">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <a href>{{ $t("organization.profReg.home") }}</a>
              </li>
              <li>
                <span>{{ $t("organization.profReg.pReg") }}</span>
              </li>
            </ul>
          </div>

          <div class="uk-width-1-1 uk-width-expand@m">
            <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
              <div class="uk-form-stacked">
                <header class="uk-card-header uk-text-center"><h1 class="uk-h3">
                  {{ $t("organization.profReg.pReg") }}
                </h1></header>
                <div class="uk-card-body">
                  <div v-if="error!==null" class="uk-alert-warning" uk-alert>
                    <p v-html="error" class="uk-text-center"></p>
                  </div>
                  <div class="uk-grid-medium uk-child-width-1-2" uk-grid>
                    <fieldset class="uk-fieldset uk-width-1-1 uk-text-center">
                      <!--<legend class="uk-h4">{{$t("organization.profReg.pImage")}}</legend>-->
                      <div>
                        <section>
                          <div uk-form-custom
                               class="js-upload uk-margin-auto uk-visible-toggle uk-position-relative uk-border-circle uk-overflow-hidden uk-light">
                            <input type="file" id="file" ref="file" @change="previewImage"
                                   accept="image/*" style="font-size: 0px; ">

                            <div v-if="imageData.length == 0">
                              <img class="uk-padding-small" tabindex="-1" width="150"
                                   src="../../assets/user.png">
                            </div>
                            <div v-else>
                              <img class="uk-padding-small" tabindex="-1" width="150"
                                   :src="imageData">
                            </div>

                            <a class="uk-link-reset uk-overlay-primary uk-position-cover uk-hidden-hover"
                               href="#">
                              <div class="uk-position-center"><span
                                  uk-icon="icon: camera; ratio: 1.25;"></span></div>
                            </a>
                          </div>
                        </section>
                      </div>
                    </fieldset>
                    <fieldset class="uk-fieldset uk-width-1-1">
                      <!--<legend class="uk-h4">{{$t("organization.profReg.contact")}}</legend>-->
                      <div class="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s" uk-grid>
                        <div><label>
                          <div class="uk-form-label">{{ $t("organization.profReg.fName") }}
                            <span style="color: red">*</span></div>
                          <input v-model="firstname" class="uk-input" type="text"/></label>
                        </div>
                        <!--                                                <div>-->
                        <!--                                                    <label>-->
                        <!--                                                        <div class="uk-form-label">{{$t("organization.profReg.lname")}}<span-->
                        <!--                                                                style="color: red">*</span>-->
                        <!--                                                        </div>-->
                        <!--                                                        <input class="uk-input" type="text" v-model="lastname"/>-->
                        <!--                                                    </label>-->
                        <!--                                                </div>-->
                        <div>
                          <label>
                            <div class="uk-form-label">{{ $t("ID") }}
                              <span style="color: red">*</span></div>
                            <input v-if="$route.params.id == '0'" class="uk-input"
                                   type="text" v-model="username"/>
                            <input v-else disabled class="uk-input" type="text"
                                   v-model="username"/>
                          </label>
                        </div>

                        <div>
                          <label>
                            <div class="uk-form-label">{{ $t("organization.profReg.email") }}
                              <span style="color: red">*</span></div>
                            <input class="uk-input" type="email"
                                   v-if="$route.params.id == '0'"
                                   v-model="email"/>
                            <input class="uk-input" disabled type="text" v-else
                                   v-model="email"/>
                          </label>
                        </div>
                        <div v-if="this.$route.params.id == 0">
                          <label>
                            <div class="uk-form-label">{{ $t("regpass") }}
                              <span style="color: red">*</span></div>
                            <input v-if="$route.params.id == '0'" class="uk-input"
                                   type="password" v-model="password"/>
                            <input v-else disabled class="uk-input" type="password"
                                   v-model="password"/>
                          </label>
                        </div>
                        <!--                                                <div v-if="$route.params.id == 0">-->
                        <!--                                                    <label>-->
                        <!--                                                        <div class="uk-form-label">Password <span-->
                        <!--                                                                style="color: red">*</span></div>-->
                        <!--                                                        <input class="uk-input" type="password"-->
                        <!--                                                               v-model="password"/></label>-->
                        <!--                                                </div>-->
                        <!--                                                <div v-else>-->
                        <!--                                                    <label>-->
                        <!--                                                        <div class="uk-form-label">Password <span-->
                        <!--                                                                style="color: red">*</span></div>-->
                        <!--                                                        <input class="uk-input" disabled-->
                        <!--                                                               type="text" v-model="password"/></label>-->
                        <!--                                                </div>-->
                        <div>
                          <label>
                            <div class="uk-form-label">{{ $t("organization.profReg.gender") }}
                            </div>
                            <select v-model="gender" class="uk-select">
                              <option value="man">{{ $t("organization.profReg.man") }}</option>
                              <option value="women">{{ $t("organization.profReg.women") }}
                              </option>
                            </select>
                          </label>
                        </div>
                        <div>
                          <label>
                            <div class="uk-form-label">{{ $t("organization.profReg.pNumber") }}</div>
                            <input class="uk-input" type="tel" v-model="phone"/>
                          </label>
                        </div>
                        <div>
                          <label>
                            <div class="uk-form-label">{{ $t("organization.profReg.dBirth") }}
                            </div>
                            <date-picker
                                class="date-picker uk-width-1-1"
                                v-model="birthday"
                                :placeholder="$t('birthDate')"
                                :lang="lang"
                            ></date-picker>
                            <!-- <input class="uk-input" type="date" v-model="birthday"/> -->
                          </label>
                        </div>
                        <div>
                          <label>
                            <div class="uk-form-label">{{ $t("tType") }} :</div>
                            <div class="uk-flex" style="padding: 5px;">
                              <input style="margin: 2px;" class="uk-radio" type="radio" v-model="type" value="professor"
                                     name="profType"/>
                              <label class="uk-form-label uk-margin-small-left"> {{ $t("Professor") }}</label>
                              <input style="margin: 2px;" class="uk-radio uk-margin-left" type="radio" v-model="type"
                                     value="proctor" name="profType"/>
                              <label class="uk-form-label uk-margin-small-left"> {{ $t("Proctor") }}</label>
                            </div>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <input type="hidden" id="submitbutton"/>
                </div>
                <div class="uk-card-footer uk-text-center">
                  <button type="button" v-if="this.$route.params.id != 0" @click="passwordNew()"
                          v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN'"
                          class="uk-button uk-button-default uk-margin-right">
                    {{ $t("changePass") }}
                  </button>
                  <button type="submit" class="uk-button uk-button-primary" @click="save()"
                          v-if="this.$route.params.id == 0">{{ $t("save") }}
                  </button>
                  <button type="button" @click="userUpdate()" v-else
                          class="uk-button uk-button-primary">{{ $t("organization.stuReg.update") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="passModel" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center"
                  style="font-size: 21px; font-weight: 700;">{{ $t("uchaPass") }}</h2>
            </div>
            <div class="uk-modal-body uk-text-left">
              <h3>{{ $t("confirmQPass") }}</h3><br>
              <div class="uk-flex">
                <input type="pass" class="uk-input" v-model="password" v-if="showPass == true">
                <input type="text" class="uk-input" v-model="password" v-else>
                <button type="button" class="uk-button uk-button-danger uk-button-small uk-margin-left"
                        @click="password = ''" style="min-width: 80px;">{{ $t("clear") }}</button>
                <button type="button" class="uk-button uk-button-default uk-button-small uk-margin-left"
                        @click="showPass = !showPass" style="min-width: 80px;">
                  <span v-if="showPass == true">{{ $t("show") }}</span>
                  <span v-else>{{ $t("hide") }}</span>
                </button>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">
                {{ $t("close") }}
              </button>
              <button type="button" class="uk-button uk-button-primary uk-button-small uk-margin-left"
                      @click="userUpdate()">
                {{ $t("navMenu.save") }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <page-footer/>

    </div>
  </layout>
</template>


<script scoped>
import Layout from '../../layouts/Layout'
import UserService from '@/services/UserService'
import OrganizService from '@/services/OrganizService'
import UIkit from 'uikit'
import {mapGetters} from 'vuex'
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import CategoryDataService from "@/services/CategoryDataService"
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");

export default {
  name: 'Home',
  components: {
    Layout,
    DatePicker,
    PageHeader,
    PageFooter
  },
  data() {
    return {
      showPass: true,
      firstname: '',
      lastname: '',
      phone: '',
      birthday: '',
      country: '',
      city: '',
      postcode: '',
      street: '',
      doornumber: '',
      gender: '',
      password: '',
      email: '',
      username: '',
      error: null,
      imageData: "",
      file: null,
      category1: '0',
      category2: '0',
      category3: '0',
      type: 'professor',
      mainCategory: {
        main: [],
        middle: [],
        low: []
      },
      lang: {
        days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        placeholder: {
          date: "Select Date",
          date2: "End date",
          dateRange: "Start date    ~    End date"
        }
      },
      shortcuts: [
        {
          text: "Today",
          onClick: () => {
            this.time3 = [new Date()];
          }
        }
      ],
      timePickerOptions: {
        start: "00:00",
        step: "00:30",
        end: "23:30"
      },

    }
  },
  created() {
    this.userId = this.$route.params.id
  },
  async mounted() {
    this.loadCates("main", 1, 0);
    if (this.$route.params.id) {
      if (this.$route.params.id > 0) {
        this.userDetails(this.$route.params.id)
      }
    }
  },
  methods: {
    passwordNew() {
      UIkit.modal('#passModel').show();
    },
    previewImage: function (event) {
      this.file = this.$refs.file.files[0];
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async userDetails(uId) {
      try {
        const response = await UserService.userDetails(uId);
        let userDetail = response.data;
        this.firstname = userDetail.firstName;
        this.lastname = userDetail.lastName;
        this.phone = userDetail.phone;
        this.birthday = userDetail.birth;
        this.gender = userDetail.gender;
        this.email = userDetail.email;
        this.password = userDetail.password;
        this.username = userDetail.username;
        if (userDetail.roles.length > 0)
          if (userDetail.roles[0].name == "ROLE_PROCTOR") {
            this.type = 'proctor'
          } else {
            this.type = 'professor'
          }

        if (userDetail.image != null)
          if (userDetail.image.length > 0) {
            this.imageData = '/uploadingDir/' + userDetail.image;
          }
      } catch (error) {
        console.log(error)
      }
    },
    async userUpdate() {
      try {

        if (this.firstname.length == 0) {
          alert("Please enter first name.");
          return;
        }
        let formData = new FormData();

        formData.append('userId', this.$route.params.id);
        formData.append('firstname', this.firstname);
        formData.append('email', this.email);
        formData.append('username', this.username);
        formData.append('lastname', this.lastname);
        formData.append('phone', this.phone);
        formData.append('birthday', this.birthday);
        formData.append('country', this.country);
        formData.append('city', this.city);
        formData.append('postcode', this.postcode);
        formData.append('street', this.street);
        formData.append('doornumber', this.doornumber);
        formData.append('gender', this.gender);
        formData.append('password', this.password);
        formData.append('organizCode', this.$store.getters.currentUser.organizs[0].organizId);
        formData.append('type', this.type);
        formData.append('cate1', this.category1);
        formData.append('cate2', this.category2);
        formData.append('cate3', this.category3);

        if (this.file)
          formData.append('image', this.file);
        const response = await UserService.userModify(formData);
        if (response.data.status == 200) {
          if (response.data.success) {
            UIkit.modal.alert(response.data.message).then(function () {
              console.log('Alert closed.')

            });
            this.$router.push({name: 'professorlist', params: {id: 0}})
          }
        } else {
          alert(response.data.message)
        }

      } catch (error) {
        console.log(error)
      }
    },

    async save() {
      try {

        let formData = new FormData();

        if (this.firstname.length == 0) {
          alert("Please enter first name.");
          return;
        }
        if (this.email.length == 0) {
          alert("Please enter email address.");
          return;
        }
        formData.append('firstname', this.firstname);
        formData.append('username', this.username);
        formData.append('email', this.email);
        formData.append('lastname', this.lastname);
        formData.append('phone', this.phone);
        formData.append('birthday', this.birthday);
        formData.append('country', this.country);
        formData.append('city', this.city);
        formData.append('postcode', this.postcode);
        formData.append('street', this.street);
        formData.append('doornumber', this.doornumber);
        formData.append('gender', this.gender);
        formData.append('password', this.password);
        formData.append('organizCode', this.$store.getters.currentUser.organizs[0].organizId);
        formData.append('type', this.type);
        formData.append('cate1', this.category1);
        formData.append('cate2', this.category2);
        formData.append('cate3', this.category3);

        if (this.file)
          formData.append('image', this.file);


        const response = await UserService.registerUser(formData);

        if (response.data.success) {
          UIkit.modal.alert(response.data.message).then(function () {
            console.log('Alert closed.')

          });
          this.$router.push({name: 'professorlist', params: {id: 0}})
        }


      } catch (error) {
        console.log(error.response);
        this.error = error.response.data.message;
        let organizId = this.$store.getters.currentUser.organizs[0].organizId;
        let userId = error.response.data.result.result;
        const _this = this;
        if (error.response.data.status == 898) {
          UIkit.modal.confirm('The email address is already registered at ubtcloud.me please add it to your organization').then(function () {
            _this.addUser(userId, organizId)
          }, function () {
            // console.log('Rejected.')
          });


        }

        this.scrollToTop()
      }

    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    async addUser(userId, organizId) {
      // alert(userId + "  " + organizId)
      const response = await OrganizService.userAdd({organizId: organizId, userId: userId});
      if (response.data.status == 200) {

        alert(response.data.message)
      }
    },
    categoryChange(type, catelevel) {
      if (type == "exam") {
        type = "main";
      }

      if (catelevel == "main") {
        let parentId = 0;
        if (type == "main") parentId = this.category1;
        else parentId = this.search.scate1;
        this.loadCates(type, 2, parentId);
      }
      if (catelevel == "middle") {
        let parentId = 0;
        if (type == "main") parentId = this.category2;
        else parentId = this.search.scate2;
        this.loadCates(type, 3, parentId);
      }
    },
    async loadCates(type, cateStep, parentId) {
      try {
        const response = await CategoryDataService.getCategoriesStep({
          type: type,
          cateStep: cateStep,
          parentId: parentId
        });
        if (response.data.status === 200) {
          if (type == "main") {
            //Category
            if (cateStep == 1) {
              this.mainCategory.main = response.data.result.cates;
            }
            if (cateStep == 2) {
              this.mainCategory.middle = response.data.result.cates;
            }
            if (cateStep == 3) {
              this.mainCategory.low = response.data.result.cates;
            }
          }
        } else {
          alert("ERROR: " + response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // loadKeywordData (datatype, keyword) {
    //   AnswersService.getAnswersByPage({
    //     mtype: datatype,
    //     keyword: keyword,
    //     size: 35
    //   }).then(res => {
    //     const { data } = res
    //     this.filtereddatas = data.content
    //     if (!this.filtereddatas.length) {
    //       return
    //     }
    //   })
    // },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser'])
  }

}
</script>

<style scoped>
.file-upload-form, .image-preview {
  padding: 20px;
}

.uk-card-body {
  padding: 20px 30px;
}

.uk-child-width-1-1 {
  padding-left: 25px;
  padding-right: 25px;
}

img.preview {
  width: 200px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
}

input[type="pass"] {
  -webkit-text-security: disc;
}

.uk-radio {
  width: 20px;
  height: 20px;
}
</style>
