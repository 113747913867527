import Api from '@/services/Api'

const resource = '/category'

export default {

  getCategories (params = {}) {
    return Api().get(`${resource}/list`, {
      params: params
    })
  },
  getCategoriesStep (params = {}) {
    return Api().get(`${resource}/liststep`, {
      params: params
    })
  },
  actionCategory(credentials) {
    return Api().post(`${resource}/action`, credentials)
  },
}
